import React from "react"
import styled from "styled-components"
import { segmentAction } from "../hooks/useSegmentTrack"
import PressList from "./PressList"

const PressItem = styled.div`
  border-bottom: 1px solid #ced3db;
  padding: 1.5rem 0;

  &:last-child {
    border: none;
  }

  a {
    color: white;
    transition: 0.2s;

    &:hover {
      color: var(--sparkle-red);
    }
  }
`
const Quote = styled.blockquote`
  background: linear-gradient(
    87.54deg,
    rgba(239, 71, 101, 0.9) -6.39%,
    rgba(55, 144, 226, 0.85) 51.12%,
    rgba(37, 213, 161, 0.85) 98.07%
  );
  background-size: 400% 400%;
  border-radius: 10px;
  padding: 5rem 2rem;
  text-align: center;
  margin-top: 4rem;
  animation: gradient 8s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @media screen and (max-width: 900px) {
    text-align: left;
    padding: 2rem;
    p.quote-author {
      text-align: right;
    }
  }
  p.block-quote {
    font-size: 1.5rem;
    max-width: 650px;
    margin: 0 auto;
    margin-bottom: 1rem;

    @media screen and (max-width: 900px) {
      font-size: 1.25rem;
    }
  }
`

const PagePress = () => {
  return (
    <>
      <h1 style={{ marginBottom: "4rem" }}>In the News</h1>
      <Quote>
          <p className="block-quote">
            <strong>
              If you would like to interview Michelle about AI technology,
        supporting small businesses, or social entrepreneurship, please fill out this {" "}
        <HighlightLink
         color= "white"
         href="https://www.wesparkle.co/contact/book-michelle">
            {" "}<u>form</u>
        </HighlightLink>
            </strong>
          </p>
        </Quote>

      {PressList.map((item, i) => {
        return (
          <PressItem key={i}>
            <p style={{ fontSize: "0.85rem", marginBottom: "0.5rem" }}>
              {item.publication} — {item.date}
            </p>
            <a
              href={item.url}
              target="_blank"
              rel={"noopener noreferrer"}
              onClick={() => {
                segmentAction.track(`Press Item Click`, {
                  value: item.headline,
                })
              }}
            >
              <strong>{item.headline}</strong>
            </a>
          </PressItem>
        )
      })}
    </>
  )
}

export default PagePress
const HighlightLink = styled.a`
  color: ${(props) => props.color};
  & span {
    text-wrap: balance;
  }
`