import React, { Fragment } from "react"
import IconMN from "./icons/IconMN"
import IconHeart from "../icons/IconHeart"
import styled from "styled-components"

import PageContactNewsletter from "./PageContactNewsletter"
import { segmentAction } from "../hooks/useSegmentTrack"
import { IconLinks } from "../icons/IconLinks"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Container = styled.div`
  a {
    color: #fff;
    text-decoration: underline;
    text-underline-offset: 5px;
  }
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

const socials = [
  {
    url: "https://www.instagram.com/wesparkleco/",
    name: "instagram",
    key: "wesparkle-instagram",
  },
  {
    url: "https://twitter.com/wesparkleco",
    name: "twitter",
    key: "wesparkle-twitter",
  },
  {
    url: "https://www.linkedin.com/company/we-sparkle/",
    name: "linkedin",
    key: "wesparkle-linkedin",
  },
  {
    url: "https://www.facebook.com/wesparkleco",
    name: "facebook",
    key: "wesparkle-facebook",
  },
  {
    url: "https://www.youtube.com/channel/UCFXtjIagJiqxaBkIB75gPOw/videos",
    name: "youtube",
    key: "wesparkle-youtube",
  },
]

const PageContactInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`
const PageContactSocials = styled.div``
const SocialHover = styled.span`
  svg {
    fill: #fff;
    transition: 0.2s;
  }
  :hover {
    svg {
      fill: var(--sparkle-yellow);
      transform: scale(1.15);
    }
  }
`
const year = new Date().getFullYear()
const termsElems = [
  <a
    key={"terms"}
    href={
      "https://community.sprkl.es/portal/en/kb/articles/we-sparkle-terms-conditions"
    }
    onClick={() =>
      segmentAction.track("Homepage Contact Click", { value: "terms" })
    }
    target="_blank"
    rel="noopener noreferrer"
  >
    Terms
  </a>,
  <a
    key={"privacy"}
    href={
      "https://community.sprkl.es/portal/en/kb/articles/we-sparkle-privacy-policy"
    }
    onClick={() =>
      segmentAction.track("Homepage Contact Click", { value: "privacy" })
    }
    target="_blank"
    rel="noopener noreferrer"
  >
    Privacy
  </a>,
  <Link
    key={"press"}
    to={"/press"}
    onClick={() =>
      segmentAction.track("Homepage Contact Click", { value: "press" })
    }
  >
    Press
  </Link>,
]

const footerElems = [
  <a
    key={"email"}
    href={"mailto:info@wesparkle.co"}
    onClick={() =>
      segmentAction.track("Homepage Contact Click", { value: "email" })
    }
  >
    info@wesparkle.co
  </a>,
  <>
    <span style={{ whiteSpace: "nowrap", lineHeight: 2 }}>
      825 Washington Ave SE #200,&nbsp;
    </span>
    <span style={{ whiteSpace: "nowrap", lineHeight: 2 }}>
      Minneapolis, MN 55414
    </span>
  </>,
]

const PageContact = () => {
  return (
    <Container>
      <PageContactInfo>
        <PageContactSocials>
          <h3>Follow Us</h3>
          <p style={{ fontSize: "1rem", lineHeight: "1.5", padding: "1rem 0" }}>
            Stay up to date on the latest digital tools we're offering small
            business owners + the organizations who support them!
          </p>
          <div style={{ marginTop: "1rem" }}>
            {socials.map(({ name, url, key }) => {
              return (
                <SocialHover
                  key={key}
                  onClick={() => {
                    segmentAction.track(`Homepage Social Media Click`, {
                      value: name,
                    })
                  }}
                  style={{ marginRight: 20 }}
                  as={"a"}
                  href={url}
                  target={"_blank"}
                >
                  <IconLinks name={name} style={{ width: 30, height: 30 }} />
                </SocialHover>
              )
            })}
          </div>
          {/* <Link
        to="/press"
        style={{fontSize: "0.85rem", marginTop: "1rem", display: "block"}}>
          Press & Publicity
        </Link> */}
        </PageContactSocials>
        <PageContactNewsletter />
      </PageContactInfo>
      <div
        style={{
          marginTop: "65px",
          fontSize: ".8em",
        }}
      >
        <FlexContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBlock: 20,
            }}
          >
            <p
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ position: "relative" }}>
                <IconMN
                  style={{
                    width: 110,
                    height: "auto",
                    fill: "#fff",
                  }}
                  alt={"Minnesota State"}
                />
                <IconHeart
                  style={{
                    position: "absolute",
                    left: 25,
                    top: 45,
                    width: 35,
                    height: "auto",
                    fill: "var(--sparkle-red)",
                  }}
                  alt={"heart"}
                />
              </span>
            </p>
            <p
              style={{
                display: "flex",
                flexDirection: "column",

                paddingLeft: "10px",
              }}
            >
              <span
                style={{
                  fontSize: "1em",
                  marginBlock: 10,
                }}
              >
                Made with love
                <br /> in Minnesota
              </span>
              <span>
                <small
                  style={{
                    fontSize: "0.7em",
                    lineHeight: "0.75em",
                  }}
                >
                  Mni Sota Makoce, Dakota and <br />
                  Anishinaabe land
                </small>
              </span>
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBlock: 20,
            }}
          >
            <p
              style={{
                textAlign: "right",
                paddingRight: "15px",
                fontSize: "0.9em",
              }}
            >
              We Sparkle Co. is a CERT <br />
              Small / Minority / Woman-Owned <br />
              Public Benefit Corporation
            </p>
            <StaticImage
              id={"wbenc-seal"}
              src={"../components/FooterContent/imgs/WBE_Seal_REVERSE_RGB.png"}
              style={{ width: 175, height: "auto" }}
              alt={"WBENC Seal"}
            />
          </div>
        </FlexContainer>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            marginBlock: " 50px",
          }}
        >
          <div
            style={{
              paddingBlock: "5px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {footerElems.map((el, i) => {
              return (
                <Fragment key={i}>
                  {el}
                  {i !== footerElems.length - 1 ? " • " : ""}
                </Fragment>
              )
            })}
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              marginBottom: "15px",
            }}
          >
            <span>
              © {year} We Sparkle Co &nbsp;•&nbsp; All Rights Reserved &nbsp;•
              &nbsp;
            </span>
            <span>
              {termsElems.map((el, i) => {
                return (
                  <Fragment key={i}>
                    {el}
                    {i !== termsElems.length - 1 ? " • " : ""}
                  </Fragment>
                )
              })}
            </span>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default PageContact
