import React from "react"
import Seo from "../core/Seo"
import PressLayout from "../press/Page"

const PressPage = () => {
  return (
    <>
      <Seo
        title="We Sparkle • Simple Tools for Solopreneurs"
        includeZohoTracking
      />
      <PressLayout />
    </>
  )
}

export default PressPage
